@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "Assistant";
    src: url('../Assistant-Medium.ttf')
}

@font-face {
    font-family: "Assistant-ExtraBold";
    src: url('../Assistant-ExtraBold.ttf')
}

@font-face {
    font-family: "Assistant-Light";
    src: url('../Assistant-Light.ttf')
}

@font-face {
    font-family: "Assistant-Light";
    src: url('../Assistant-Light.ttf')
}

@font-face {
    font-family: "OpenSans-Medium";
    src: url('../OpenSans-Medium.ttf')
}

@font-face {
    font-family: "OpenSans-Light";
    src: url('../OpenSans-Light.ttf')
}

.curved-bottom {
    @apply bg-green-500 text-white;
    clip-path: polygon(0 0, 100% 0, 100% 85%, 50% 100%, 0 85%);
}

.curved-top {
    @apply bg-blue-700 text-white p-12;
    clip-path: polygon(0 15%, 50% 0, 100% 15%, 100% 100%, 0 100%);
}